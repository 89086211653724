<template>
  <dashboard-layout>
    <div class="w-full p-5">
      <div class="flex flex-col">
        <div class="w-11/12 mx-auto">
          <div
            class="flex border-2 border-green-1002 flex px-3 py-1 rounded-lg w-min"
          >
            <router-link :to="{ name: 'my-curriculum' }">
              <i class="ri-close-fill"></i>
            </router-link>
          </div>
          <form @submit.prevent="submit">
            <h2 class="text-2xl mb-4 font-bold">Editar áreas de interesse</h2>

            <div class="flex flex-col">
              <label class="mb-2" for="interest-areas">
                *Áreas de interesse:
              </label>
              <multiselect
                id="interest-areas"
                v-model="keywords.selected"
                tag-placeholder="Adicionar área"
                placeholder="Procure ou adicione uma área"
                label="label"
                track-by="label"
                :options="keywords.options"
                :multiple="true"
                :taggable="true"
                :searchable="true"
                :loading="isLoading"
                :clear-on-select="false"
                :close-on-select="false"
                :options-limit="300"
                :show-no-results="false"
                :hide-selected="true"
                select-label="Selecionar"
                deselect-label="Remover"
                selected-label="Selecionado"
                :internal-search="false"
                @search-change="fetchKeywords"
              >
                <span slot="noOptions"
                  >Comece a pesquisar para que as áreas apareçam aqui.</span
                >
              </multiselect>
            </div>

            <div class="flex justify-between">
              <button
                type="submit"
                class="bg-green-1000 text-white px-4 py-2 rounded rounded-md border border-gray-400 focus:outline-none mt-5"
              >
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import { mask } from 'vue-the-mask';
import axios from '@/utils/axios';
import DashboardLayout from '../../../../layouts/DashboardLayout';
import { mapGetters } from 'vuex';

export default {
  name: 'InterestAreasEdit',

  title() {
    return `${process.env.VUE_APP_NAME} | Editar Experiência Profissional do Talento`;
  },

  components: {
    DashboardLayout
  },

  directives: {
    mask
  },

  data() {
    return {
      isLoading: false,
      keywords: {
        options: [],
        selected: [],
        draw: 0
      }
    };
  },

  computed: {
    ...mapGetters('auth', {
      user: 'user'
    })
  },

  methods: {
    async fetchInterestAreas() {
      const { data: interestAreas } = await axios.get(
        `/api/areas-de-interesse/${this.user.participant_id}`
      );

      interestAreas.data.forEach(keyword => {
        this.keywords.selected.push({
          label: keyword.label,
          id: keyword.id
        });
      });
    },

    submit() {
      axios
        .patch(`/api/areas-de-interesse/${this.user.participant_id}`, {
          interest_areas: this.keywords.selected.map(keyword => keyword.id)
        })
        .then(({ data }) => {
          this.$toast.success(data.data.message);

          this.$router.push({
            name: 'my-curriculum',
            params: {
              participant: this.participant
            }
          });
        })
        .catch(({ response }) => {
          Object.values(response.data.errors).forEach(error => {
            this.$toast.error(...error);
          });
        });
    },

    fetchKeywords(search) {
      if (!search.length) {
        this.keywords.options = [];

        return;
      }

      this.isLoading = true;

      axios
        .post('api/palavras-chave', { search, draw: ++this.keywords.draw })
        .then(({ data: keywords }) => {
          if (keywords.draw !== this.keywords.draw) return;

          this.keywords.options = [];

          keywords.data.forEach(keyword =>
            this.keywords.options.push({
              label: keyword.label,
              id: keyword.id
            })
          );

          this.isLoading = false;
          this.keywords.draw = 0;
        });
    }
  },

  created() {
    this.fetchInterestAreas();
  }
};
</script>

<style scoped></style>
